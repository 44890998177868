<template>
  <div class="mt-2">
    <v-row no-gutters v-if="fieldsLoading">
      <v-col v-for="n in 9" :key="n" cols="12" sm="6" lg="3">
       <v-sheet class="mt-2">
        <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
      </v-sheet>
      </v-col>
    </v-row>
    <form-data v-else :references.sync="formReferences" :model="modelObj" ref="weldlogReference">
      <template v-if="modelObj.id && listOfDocuments.length > 0" slot="weldlog_documents">
        <documents :documents="listOfDocuments" :deleteIds="deleteDocumentIds"></documents>
      </template>
      <template slot="weld_end_complete">
        <v-row no-gutters class="">
          <v-col cols="2" class="px-0 py-1">
            <v-card elevation="0" width="100%" height="100%" outlined class="justify-center align-center d-flex">
              <v-checkbox v-model="modelObj.is_completed" class="mt-0" @change="setWeldEnd('weld_end')" hide-details></v-checkbox>
            </v-card>
          </v-col>
          <v-col cols="10" class="py-1 px-0">
            <v-card elevation="0">
              <!-- <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="modelObj.weld_end" :label="$t('message.projectFeature.weldEnd')" outlined dense hide-details readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-if="modelObj.is_completed" first-day-of-week='1' :weekday-format="getDayFormat" @input="menu2 = false; modelObj.weld_end = $formatter.formatDate(modelObj.weld_end, 'DD.MM.YYYY')" v-model="modelObj.weld_end"></v-date-picker>
              </v-menu> -->
              <v-text-field readonly v-model="modelObj.weld_end" outlined dense hide-details :label="$t('message.projectFeature.weldEnd')" style="width:100%;"></v-text-field>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template slot="warehouse_product_batchno">
        <div class="custom_border">
          <v-row class="mx-0">
            <v-col class="pa-0" cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" width="50%">{{$t('message.projectFeature.productNumber')}}</th>
                      <th class="text-left" width="50%">{{$t('message.projectFeature.batchNr')}}</th>
                    </tr>
                  </thead>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row class="mx-0" v-for="(productBatch, index) in warehouseProductBatches" :key="index">
            <v-col sm="6" class="pa-1">
              <v-autocomplete @change="getBatchByMaterials(index, +productBatch.product_id)" :items="listOfProducts" v-model="productBatch.product_id" :item-text="(val) => `${val.product_full_name || ''}`" item-value="product_id" hide-details outlined :label="$t('message.projectFeature.choose')" dense :rules="[]"></v-autocomplete>
            </v-col>
            <v-col sm="5" class="pa-1">
              <v-autocomplete :loading="productBatch.batchLoading" :disabled="productBatch.listOfBatches.length < 1" :items="productBatch.listOfBatches" v-model="productBatch.batch_no" item-text="name" item-value="_id" hide-details outlined :label="$t('message.projectFeature.choose')" dense :rules="[]"></v-autocomplete>
            </v-col>
            <v-col sm="1" class="pa-1">
             <v-btn v-if="warehouseProductBatches && warehouseProductBatches.length > 1" class="mx-1" style="float:right;" fab x-small color="primary" @click="removeBatchRow(index)">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-col>
          </v-row>
          <v-row class="mx-0">
            <v-spacer></v-spacer>
            <v-btn class="mx-1" style="float:right;" fab x-small color="primary" @click="scanQrCode()">
              <v-icon>mdi-qrcode-scan</v-icon>
            </v-btn>
            <v-btn class="mx-1" style="float:right;" fab x-small color="primary" @click="addNewProductBatchObj()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </div>
       </template>
    </form-data>
    <v-dialog v-model="scanQRDialog" persistent max-width="500px" class="mt-0">
        <v-card>
          <v-card-title>
          </v-card-title>
        <v-spacer></v-spacer>
             <v-card-text class="pa-0">
      <v-form ref="pickForm" v-on:submit.prevent>
        <v-row>
          <v-col cols="12" class="pt-0 pr-0 pb-0 mt-1">
            <barcode-scanner v-model="scanCode" :search="scanCode" :scannerPayload="scannerPayload"
            :loading="productLoading"></barcode-scanner> <!--  :disabled="isAllPicked" -->
            <!-- <v-text-field outlined dense hide-details v-model="scanCode" flat :disabled="isAllPicked" autocomplete="off"
              ref="scanFocus" @change="getRespectiveProductOrder" :loading="productLoading"
            :placeholder="$t('message.order.scanProduct')"></v-text-field> -->
          </v-col>
          <v-col cols="12" class="pt-1 pb-1 font-weight-bold" v-if="productFound">
            {{ $t('message.equipments.product') }}: {{ productInfo.description || '' }}
          </v-col>
          <template v-if="productFound && productInfo.isbatch"><!--  && (productInfo.producttype === 'product') -->
            <v-col cols="7" class="pt-0 pb-0">
              <v-autocomplete :items="getbatch" v-model="selectBatch" item-text="batchnumberpwa" item-value="batchnumberpwa"
                hide-details outlined :loading="loadingBatch" :label="$t('message.product.batch')" flat autocomplete="off"
                dense :rules="$_requiredValidation" ref="batchRef" @change="checkTheBatchnumber"></v-autocomplete>
            </v-col>
            <v-col cols="5" class="pl-0 pt-0 pb-0" v-if="moreLocationsFound">
                <v-autocomplete :items="batchLocations" v-model="selectedLocation" :item-text="getItemText" item-value="locationname"
                hide-details outlined :loading="loadingLocation" :label="$t('message.product.scanLoc')" flat autocomplete="off"
                dense :rules="$_requiredValidation" ref="moreLocation" @change="checkIfLocationValid"></v-autocomplete>
            </v-col>
          </template>
          <v-col cols="8" class="pt-0 pb-0" v-if="productFound && !productInfo.isbatch"><!--  && (productInfo.producttype === 'product') -->
              <v-autocomplete :items="listOfLocations" v-model="selectedLocation" :item-text="getItemText" item-value="locationname"
                hide-details outlined :loading="loadingLocation" :label="$t('message.product.scanLoc')" flat autocomplete="off"
                dense :rules="$_requiredValidation" ref="moreLocation" @change="checkIfLocationValid"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="productFound">
          <v-col cols="9" class="pa-1">
            <v-row>
              <v-col cols="3" class="text-center pa-0" @click="decreaseQty">
                <v-btn color="info" fab x-small>
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" class="pa-0">
                <v-text-field outlined dense hide-details type="number" class="text-right" v-model="pickQty"
                  autocomplete="off" ref="qtyRef" :rules="$_qtyValidation" :placeholder="$t('message.product.qty')"></v-text-field>
              </v-col>
              <v-col cols="3" class="text-center pa-0" @click="increaseQty">
                <v-btn color="info" fab x-small>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3" class="pa-1 mt-1">
            <v-btn color="primary" dark small @click="pickQtyOnOrder" :loading="saveLoading">
              {{ $t('message.order.pick') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="error" @click="cancelScanQRDialog">{{ $t('message.common.cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { hostAppApi } from '@/plugins/axios_settings'
import PermissionsMixin from '@/mixins/permissions'
import FeaturesMixin from '@/mixins/features_list'
import moment from 'moment'
export default {
  mixins: [PermissionsMixin, FeaturesMixin],
  data () {
    return {
      valueDrawing: 1,
      listOfProjects: [],
      projectObj: {},
      modelObj: {
        id: parseInt(this.$route.params.id),
        po_ranked: 0,
        project_id: parseInt(this.$route.params.project_id),
        vt_sign: '',
        rt_sign: '',
        ut_sign: '',
        mt_sign: '',
        wps_nr: '',
        pipe_description: ''
      },
      loading: false,
      projectId: parseInt(this.$route.params.project_id) || 0,
      fieldsLoading: false,
      hostUserId: this.$cookie.get(process.env.VUE_APP_HOST_USER) ? this.$cookie.get(process.env.VUE_APP_HOST_USER) : 0,
      backUrl: '',
      listOfDocuments: [],
      deleteDocumentIds: [],
      hideDetails: true,
      listOfDrawings: [],
      listOfprojectwps: [],
      currentUserSign: '',
      warehouseEnabled: false,
      warehouseProductBatches: [],
      listOfProducts: [],
      productBatchesObj: {},
      disabledField: null,
      weldCompound: [],
      weldlog_fault_types: [],
      weldingLength: false,
      weld_compond: false,
      welders_id: [],
      // qrcode
      scanQRDialog: false,
      orderId: 0,
      orderModel: {},
      scanCode: '',
      windowSize: {
        x: 0,
        y: 0
      },
      productFound: false,
      productInfo: {},
      pickQty: '',
      listOfLocations: [],
      selectedLocation: '',
      selectBatch: '',
      saveLoading: false,
      loadingBatch: false,
      isAllPicked: false,
      moreLocationsFound: false,
      batchLocations: [],
      getbatch: [],
      productLoading: false,
      loadingLocation: false,
      listOfWarehouses: [],
      selectedWarehouse: 0,
      locationsSet: false,
      /* Scanner field */
      scannerPayload: {
        placeholder: 'message.order.scanProduct',
        change: this.getRespectiveProductOrder,
        attrRef: 'scannerFieldFocus',
        attrId: 'scannerField'
      },
      unPlannedProduct: false
      // menu2: false
    }
  },
  computed: {
    ...mapGetters(['formType', 'generalSettings', 'userDetails', 'getHostRefApi', 'userId']),
    formReferences () {
      if (!this.disabledField) {
        return {
          cardFlat: true,
          cardTitleClass: 'pa-1',
          layoutClass: 'pa-1',
          title: this.modelObj.id ? this.$t('message.common.update') : `${this.$t('message.common.addNew')}`,
          properties: [{
            model: 'po_ranked',
            type: this.formType.TEXT,
            rules: [],
            label: this.$t('message.projectFeature.poRanked'),
            class: 'xs12 sm4 md3 lg3',
            readonly: true
          }, {
            model: 'drawing_nr',
            type: this.formType.COMBOBOX,
            items: this.listOfDrawings,
            rules: this.$_requiredValidation, // $_numberValidation,
            multiple: false,
            label: this.$t('message.projectFeature.drawingNumber'),
            class: 'xs12 sm4 md3 lg3'
          }, {
            model: 'ao_ranked',
            type: this.formType.TEXT,
            rules: [],
            label: this.$t('message.projectFeature.aoRanked'),
            class: 'xs12 sm4 md3 lg3',
            readonly: true
          }, {
            model: 'ndt_specification',
            type: this.formType.TEXT,
            rules: [],
            label: this.$t('message.projectFeature.NDTSpecifications'),
            class: 'xs12 sm4 md3 lg3'
          }, {
            model: 'ndt_scope',
            type: this.formType.TEXT,
            rules: [],
            label: this.$t('message.projectFeature.NDTScope'),
            class: 'xs12 sm4 md3 lg3'
          }, {
            model: 'filler_materials',
            type: this.formType.TEXT,
            rules: [],
            label: this.$t('message.projectFeature.filterMaterials'),
            class: 'xs12 sm4 md3 lg3',
            is_show: !this.warehouseEnabled
          }, {
            model: 'charge_nr',
            type: this.formType.TEXT,
            rules: [],
            label: this.$t('message.projectFeature.chargedLotNumber'),
            class: 'xs12 sm4 md3 lg3',
            is_show: !this.warehouseEnabled
          }, {
            type: 'warehouse_product_batchno',
            slot: 'warehouse_product_batchno',
            class: 'md5 sm8',
            is_show: this.warehouseEnabled
          }, {
            model: 'files',
            type: this.formType.FILES,
            multiple: true,
            rules: this.$_maxFileSizeValidation(100, this.modelObj.files), // [], // this.$_multiSelectValidation
            label: this.$t('message.customModule.uploadDocument'),
            class: 'xs12 sm4 md3 lg3',
            slot: 'weldlog_documents',
            hideDetails: this.hideDetails/* ,
            is_show: !this.warehouseEnabled */
          }, {
            model: 'project_wps_nr',
            type: this.formType.SELECT,
            rules: [],
            items: this.listOfprojectwps,
            select_text: 'name',
            select_value: 'id',
            disabled: false,
            label: this.$t('message.projectFeature.wpsNumber'),
            class: 'xs12 sm4 md3 lg3',
            multiple: true,
            change: this.updateWPSnr
          }, {
            model: 'wps_nr',
            type: this.formType.TEXTAREA,
            rules: [],
            label: this.$t('message.projectFeature.wpsNumber'),
            class: 'xs12 sm4 md3 lg3',
            rows: 3,
            showHint: true,
            hintMsg: this.$t('message.projectFeature.wpsAsComma')
          },
          // {
          //   model: 'weld_end',
          //   type: this.formType.DATEPICKER,
          //   rules: this.modelObj.is_completed ? this.$_requiredValidation : [],
          //   picker: 'weldEndDatePicker',
          //   menu: 'weldEndDateMenu',
          //   color: 'primary lighten-1',
          //   label: this.$t('message.projectFeature.weldEnd'),
          //   class: 'xs12 sm4 md3 lg3'
          // },
          {
            type: 'weld_end_complete',
            slot: 'weld_end_complete',
            class: 'xs12 sm4 md3 lg3'
          },
          {
            model: 'vt_sign',
            type: this.formType.TEXT,
            rules: [],
            appendIcon: 'mdi-check-bold',
            appendClick: () => this.setSignDate('vt_sign'),
            label: this.$t('message.projectFeature.vtSignDate'),
            class: 'xs12 sm4 md3 lg3'
          }, {
            model: 'rt_sign',
            type: this.formType.TEXT,
            rules: [],
            appendIcon: 'mdi-check-bold',
            appendClick: () => this.setSignDate('rt_sign'),
            label: this.$t('message.projectFeature.rtSignDate'),
            class: 'xs12 sm4 md3 lg3'
          }, {
            model: 'ut_sign',
            type: this.formType.TEXT,
            rules: [],
            appendIcon: 'mdi-check-bold',
            appendClick: () => this.setSignDate('ut_sign'),
            label: this.$t('message.projectFeature.utSignDate'),
            class: 'xs12 sm4 md3 lg3'
          }, {
            model: 'mt_sign',
            type: this.formType.TEXT,
            rules: [],
            appendIcon: 'mdi-check-bold',
            appendClick: () => this.setSignDate('mt_sign'),
            label: this.$t('message.projectFeature.mtPtSignDate'),
            class: 'xs12 sm4 md3 lg3'
          }, {
            model: 'comments',
            type: this.formType.TEXTAREA,
            rules: [],
            label: this.$t('message.common.comments'),
            class: 'xs12 sm4 md3 lg3',
            rows: 3
          }, {
            model: 'created_at',
            type: this.formType.DATEPICKER,
            rules: [],
            picker: 'createdOnDatePicker',
            menu: 'createdOnDateMenu',
            color: 'primary lighten-1',
            label: this.$t('message.projectFeature.createdOn'),
            class: 'xs6 sm4 md3 lg3'
          },
          // {
          //   model: 'is_completed',
          //   rules: [],
          //   type: this.formType.CHECKBOX,
          //   class: 'xs6 sm4 md3 lg3 mt-1 custom_checkbox_margin',
          //   label: this.$t('message.customModule.isCompleted'),
          //   change: this.setWeldEnd('weld_end')
          // },
          {
            model: 'welding_id',
            type: this.formType.TEXT,
            rules: [],
            label: this.$t('message.projectFeature.weldingID'),
            class: 'xs6 sm4 md3 lg3'
          },
          // {
          //   model: 'welding_id',
          //   type: this.formType.COMBOBOX,
          //   items: this.welders_id,
          //   rules: [],
          //   label: this.$t('message.projectFeature.weldingID'),
          //   class: 'xs12 sm4 md3 lg3'
          //   // change: this.setWelders()
          // },
          {
            model: 'weld_nr',
            type: this.formType.TEXT,
            rules: [],
            label: this.$t('message.projectFeature.weldNumber'),
            class: 'xs6 sm4 md3 lg3'
          }],
          buttons: [{
            name: 'save_weldlog',
            color: 'success',
            label: this.modelObj.id ? this.$t('message.common.update') : this.$t('message.common.save'),
            click: this.saveUpdateHandler,
            loading: this.loading,
            is_show: true
          }, {
            name: 'back',
            color: 'info',
            click: () => this.$router.go(-1), //  this.$router.push(this.backUrl),
            label: this.$t('message.login.back'),
            to: this.backUrl,
            is_show: true
          }]
        }
      } else {
        return {
          cardFlat: true,
          cardTitleClass: 'pa-1',
          layoutClass: 'pa-1',
          title: this.modelObj.id ? this.$t('message.common.update') : `${this.$t('message.common.addNew')}`,
          properties: [{
            model: 'po_ranked',
            type: this.formType.TEXT,
            rules: [],
            label: this.$t('message.projectFeature.poRanked'),
            class: 'xs12 sm4 md3 lg3',
            readonly: true
          }, {
            model: 'drawing_nr',
            type: this.formType.COMBOBOX,
            items: this.listOfDrawings,
            rules: this.$_requiredValidation, // $_numberValidation,
            multiple: false,
            label: this.$t('message.projectFeature.drawingNumber'),
            class: 'xs12 sm4 md3 lg3',
            manditary: true,
            change: this.changeDrawingNumber()
          }, {
            model: 'ao_ranked',
            type: this.formType.TEXT,
            rules: [],
            label: this.$t('message.projectFeature.aoRanked'),
            class: 'xs12 sm4 md3 lg3',
            readonly: true
          }, {
            model: 'ndt_specification',
            type: this.formType.TEXT,
            rules: [],
            label: this.$t('message.projectFeature.NDTSpecifications'),
            class: 'xs12 sm4 md3 lg3'
          }, {
            model: 'weldlog_compound_id',
            type: this.formType.AUTO_COMPLETE,
            items: this.weldCompound,
            select_text: 'name',
            select_value: 'id',
            rules: [],
            label: this.$t('message.projectFeature.weldingCompound'),
            class: 'xs12 sm4 md3 lg3'
            // change: this.changeWeldCompound()
          }, {
            model: 'is_diameter',
            rules: [],
            type: this.formType.CHECKBOX,
            class: 'xs6 sm4 md3 lg3 mt-1 custom_checkbox_margin',
            label: this.$t('message.projectFeature.isDiameter'),
            change: this.changeLength()
          }, {
            model: 'pipe_description',
            type: this.formType.TEXT,
            rules: [],
            label: this.$t('message.projectFeature.pipe_description'),
            class: 'xs12 sm4 md3 lg3',
            max: 30,
            is_show: this.weldingLength ? this.weldingLength : false
          }, {
            model: this.weldingLength ? 'weld_diameter' : 'weld_length',
            type: this.formType.TEXT,
            rules: this.$_lengthValidation,
            max: 10,
            label: this.weldingLength ? this.$t('message.projectFeature.weld_diameter') : this.$t('message.projectFeature.weldLength'),
            class: 'xs12 sm4 md3 lg3'
          }, {
            model: 'welding_id',
            type: this.formType.COMBOBOX,
            items: this.welders_id,
            multiple: true,
            rules: [],
            label: this.$t('message.projectFeature.weldingID'),
            class: 'xs12 sm4 md3 lg3'
            // change: this.setWelders()
          }, {
            model: 'filler_materials',
            type: this.formType.TEXT,
            rules: [],
            label: this.$t('message.projectFeature.filterMaterials'),
            class: 'xs12 sm4 md3 lg3',
            is_show: !this.warehouseEnabled
          }, {
            model: 'charge_nr',
            type: this.formType.TEXT,
            rules: [],
            label: this.$t('message.projectFeature.chargedLotNumber'),
            class: 'xs12 sm4 md3 lg3',
            is_show: !this.warehouseEnabled
          }, {
            type: 'warehouse_product_batchno',
            slot: 'warehouse_product_batchno',
            class: 'md5 sm8',
            is_show: this.warehouseEnabled
          }, {
            model: 'files',
            type: this.formType.FILES,
            multiple: true,
            rules: this.$_maxFileSizeValidation(100, this.modelObj.files), // [], // this.$_multiSelectValidation
            label: this.$t('message.customModule.uploadDocument'),
            class: 'xs12 sm4 md4 lg4',
            slot: 'weldlog_documents',
            disabled: this.fileDisabled,
            hideDetails: this.hideDetails/* ,
            is_show: !this.warehouseEnabled */
          }, {
            model: 'weld_nr',
            type: this.formType.TEXT,
            rules: [],
            label: this.$t('message.projectFeature.weldNumber'),
            class: 'xs12 sm4 md4 lg4'
          }, {
            model: 'project_wps_nr',
            type: this.formType.SELECT,
            rules: [],
            items: this.listOfprojectwps,
            select_text: 'name',
            select_value: 'id',
            disabled: false,
            label: this.$t('message.projectFeature.wpsNumber'),
            class: 'xs12 sm4 md4 lg4',
            multiple: true,
            change: this.updateWPSnr
          }, {
            model: 'wps_nr',
            type: this.formType.TEXTAREA,
            rules: [],
            label: this.$t('message.projectFeature.wpsNumber'),
            class: 'xs12 sm4 md4 lg4',
            rows: 3,
            showHint: true,
            hintMsg: this.$t('message.projectFeature.wpsAsComma')
          }, {
            model: 'designation_1',
            type: this.formType.TEXT,
            rules: this.$_lengthValidation,
            max: 10,
            label: this.$t('message.projectFeature.designation_1'),
            class: 'xs12 sm4 md4 lg4'
          }, {
            model: 'thickness_1_1',
            type: this.formType.TEXT,
            rules: this.$_lengthValidation,
            max: 10,
            label: this.$t('message.projectFeature.batch_lot'),
            class: 'xs12 sm4 md4 lg4'
          }, {
            model: 'thickness_1',
            type: this.formType.TEXT,
            rules: this.$_lengthValidation,
            max: 10,
            label: this.$t('message.projectFeature.thickness_1'),
            class: 'xs12 sm4 md4 lg4'
          }, {
            model: 'designation_2',
            type: this.formType.TEXT,
            rules: this.$_lengthValidation,
            max: 10,
            label: this.$t('message.projectFeature.designation_2'),
            class: 'xs12 sm4 md4 lg4'
          }, {
            model: 'thickness_2_1',
            type: this.formType.TEXT,
            rules: this.$_lengthValidation,
            max: 10,
            label: this.$t('message.projectFeature.batch_lot'),
            class: 'xs12 sm4 md4 lg4'
          }, {
            model: 'thickness_2',
            type: this.formType.TEXT,
            rules: this.$_lengthValidation,
            max: 10,
            label: this.$t('message.projectFeature.thickness_2'),
            class: 'xs12 sm4 md4 lg4'
          }, {
            model: 'vt_scope',
            type: this.formType.NUMBER,
            rules: this.$_percentageValidation,
            max: 10,
            label: this.$t('message.projectFeature.ndt'),
            class: 'xs12 sm4 md4 lg4'
          }, {
            model: 'vt_sign',
            type: this.formType.TEXT,
            rules: [],
            appendIcon: 'mdi-check-bold',
            appendClick: () => this.setSignDate('vt_sign'),
            label: this.$t('message.projectFeature.vtSignDate'),
            class: 'xs12 sm4 md4 lg4'
          }, {
            model: 'vt_fault_types',
            type: this.formType.SELECT,
            rules: [],
            items: this.weldlog_fault_types,
            select_text: 'name',
            select_value: 'id',
            label: this.$t('message.projectFeature.notAccepted'),
            class: 'xs12 sm4 md4 lg4',
            multiple: true
          }, {
            model: 'rt_scope',
            type: this.formType.NUMBER,
            rules: this.$_percentageValidation,
            label: this.$t('message.projectFeature.ndt'),
            class: 'xs12 sm4 md4 lg4'
          }, {
            model: 'rt_sign',
            type: this.formType.TEXT,
            rules: [],
            appendIcon: 'mdi-check-bold',
            appendClick: () => this.setSignDate('rt_sign'),
            label: this.$t('message.projectFeature.rtSignDate'),
            class: 'xs12 sm4 md4 lg4'
          }, {
            model: 'rt_fault_types',
            type: this.formType.SELECT,
            rules: [],
            items: this.weldlog_fault_types,
            select_text: 'name',
            select_value: 'id',
            label: this.$t('message.projectFeature.notAccepted'),
            class: 'xs12 sm4 md4 lg4',
            multiple: true
          }, {
            model: 'ut_scope',
            type: this.formType.NUMBER,
            rules: this.$_percentageValidation,
            label: this.$t('message.projectFeature.ndt'),
            class: 'xs12 sm4 md4 lg4'
          }, {
            model: 'ut_sign',
            type: this.formType.TEXT,
            rules: [],
            appendIcon: 'mdi-check-bold',
            appendClick: () => this.setSignDate('ut_sign'),
            label: this.$t('message.projectFeature.utSignDate'),
            class: 'xs12 sm4 md4 lg4'
          }, {
            model: 'ut_fault_types',
            type: this.formType.SELECT,
            rules: [],
            items: this.weldlog_fault_types,
            select_text: 'name',
            select_value: 'id',
            label: this.$t('message.projectFeature.notAccepted'),
            class: 'xs12 sm4 md4 lg4',
            multiple: true
          }, {
            model: 'mt_scope',
            type: this.formType.NUMBER,
            rules: this.$_percentageValidation,
            items: this.weldlog_fault_types,
            select_text: 'name',
            select_value: 'id',
            label: this.$t('message.projectFeature.ndt'),
            class: 'xs12 sm4 md4 lg4'
          }, {
            model: 'mt_sign',
            type: this.formType.TEXT,
            rules: [],
            appendIcon: 'mdi-check-bold',
            appendClick: () => this.setSignDate('mt_sign'),
            label: this.$t('message.projectFeature.mtPtSignDate'),
            class: 'xs12 sm4 md4 lg4'
          }, {
            model: 'mt_fault_types',
            type: this.formType.SELECT,
            rules: [],
            items: this.weldlog_fault_types,
            select_text: 'name',
            select_value: 'id',
            label: this.$t('message.projectFeature.notAccepted'),
            class: 'xs12 sm4 md4 lg4',
            multiple: true
          }, {
            model: 'comments',
            type: this.formType.TEXTAREA,
            rules: [],
            label: this.$t('message.common.comments'),
            class: 'xs12 sm4 md3 lg3',
            rows: 3
          }, {
            type: 'weld_end_complete',
            slot: 'weld_end_complete',
            class: 'xs12 sm4 md3 lg3'
          }, {
            model: 'created_at',
            type: this.formType.DATEPICKER,
            rules: [],
            picker: 'createdOnDatePicker',
            menu: 'createdOnDateMenu',
            color: 'primary lighten-1',
            label: this.$t('message.projectFeature.createdOn'),
            class: 'xs12 sm4 md3 lg3'
          }
          ],
          buttons: [{
            name: 'save_weldlog',
            color: 'success',
            label: this.modelObj.id ? this.$t('message.common.update') : this.$t('message.common.save'),
            click: this.saveUpdateHandler,
            loading: this.loading,
            is_show: true
          }, {
            name: 'back',
            color: 'info',
            click: () => this.$router.go(-1), //  this.$router.push(this.backUrl),
            label: this.$t('message.login.back'),
            to: this.backUrl,
            is_show: true
          }]
        }
      }
    }
  },
  mounted () {
    this.warehouseEnabled = this.features && this.features.warehouse && this.features.warehouse.enabled
    /* check if has warehouse feature then do changes as #10063 */
    /* this.warehouseEnabled = this.checkFeatureEnabled('warehouse')
    if (this.warehouseEnabled) {
      this.warehouseEnabled = Boolean(this.warehouseEnabled)
      this.getMaterials()
    } */
    /* new crud codes adding */
    if (this.$route.params.id) {
      this.getSingleRecord(this.$route.params.id)
    }
    // set back url
    this.backUrl = this.projectId ? `/projects/${this.projectId}/weld-log` : '/weld-log'
    if (this.projectId) this.getProjectById()
    if (!this.$route.params.id) this.getFormDropdownValues() // / create method call
    this.onResizeHandler()
    this.focusField('scanFocus')
  },
  components: {
    'form-data': () => import('@/components/FormTemplate'),
    documents: () => import('@/components/Documents/Documents'),
    'barcode-scanner': () => import('@/components/BarQrCodeScanner')
  },
  methods: {
    // getDayFormat (date) {
    //   const lang = this.$i18n.locale === 'no' ? 'nb' : 'en'
    //   return moment(date).locale(lang).format('dd')
    // },
    getFormDropdownValues () {
      hostAppApi.get(`${this.getHostRefApi}project_weldlog/create?user_id=${this.hostUserId}&project_id=${this.projectId}`)
        .then((response) => {
          if (response.data) {
            Number(response.data.project.project_config.advance_weldlog) ? this.disabledField = true : this.disabledField = false
            const { drawings, projectwps, currentusersign, project } = response.data
            if (project && project.project_config && project.project_config.warehouse) {
              const warehouseForProject = project.project_config.warehouse
              if (warehouseForProject.active) {
                /* check if has warehouse feature then do changes as #10063 */
                this.warehouseEnabled = this.features && this.features.warehouse && this.features.warehouse.enabled
                this.getMaterials()
              } else this.warehouseEnabled = false
            }
            this.currentUserSign = currentusersign || ''
            var drawingList = []
            if (Object.keys(drawings).length) {
              Object.keys(drawings).forEach(key => {
                drawingList.push(drawings[key])
              })
            }
            this.listOfDrawings = drawingList || []
            this.listOfprojectwps = projectwps || []
            this.weldCompound = response.data.weldlog_compounds || []
            this.welders_id = response.data.welders || []
            // this.weldlog_fault_types = response.data.weldlog_fault_types
          }
        })
        .finally(() => {})
    },
    getProjectById () {
      this.$api.execute('get', `projects/${this.projectId}`).then((response) => {
        if (response.data) {
          this.projectObj = this.$formatter.cloneVariable(response.data)
          this.modelObj.po_ranked = this.projectObj.number || ''
          this.modelObj.ao_ranked = this.projectObj.name || ''
        }
      })
    },
    getAllProjects () {
      this.$api.execute('get', 'projects/get_as_simplified_for_pwa?filter=2').then((response) => {
        this.listOfProjects = response.data
      })
    },
    saveUpdateHandler () {
      if (this.$refs.weldlogReference.$refs.validateForm.validate()) {
        this.loading = true
        const files = this.modelObj.files
        const model = this.$formatter.cloneVariable(this.modelObj)
        model.user_id = parseInt(this.hostUserId)
        const formData = new FormData()
        if (files) {
          const Newfile = [...files]
          Newfile.forEach((file, i) => {
            formData.append('document[' + i + ']', file)
          })
        }
        delete model.created_at
        const newobj = this.$formatter.removeInvalidFromObject(model)
        newobj.vt_fault_types = this.modelObj.vt_fault_types ? this.modelObj.vt_fault_types : ''
        newobj.ut_fault_types = this.modelObj.ut_fault_types ? this.modelObj.ut_fault_types : ''
        newobj.rt_fault_types = this.modelObj.rt_fault_types ? this.modelObj.rt_fault_types : ''
        newobj.mt_fault_types = this.modelObj.mt_fault_types ? this.modelObj.mt_fault_types : ''
        newobj.weld_diameter = this.modelObj.is_diameter && this.modelObj.weld_diameter ? this.modelObj.weld_diameter : ''
        newobj.is_diameter = this.modelObj.is_diameter ? (this.modelObj.is_diameter === true || this.modelObj.is_diameter === 1) ? 1 : 0 : ''
        newobj.weld_length = !this.modelObj.is_diameter && this.modelObj.weld_length ? this.modelObj.weld_length : ''
        newobj.weldlog_compound_id = this.modelObj.weldlog_compound_id ? this.modelObj.weldlog_compound_id : ''
        newobj.welding_id = this.modelObj.welding_id_tmp ? this.modelObj.welding_id_tmp : this.modelObj.welding_id
        newobj.weldlog_expertise_id = this.modelObj.weldlog_expertise_id_tmp ? this.modelObj.weldlog_expertise_id_tmp : this.modelObj.weldlog_expertise_id
        // newobj.weldlog_expertise_id = this.modelObj.weldlog_expertise_id_tmp ? this.modelObj.weldlog_expertise_id_tmp : this.modelObj.weldlog_expertise_id
        // const welder = !this.modelObj.id ? this.welders_id.find(val => this.modelObj.weldlog_expertise_id.expertise_id ? val.expertise_id === this.modelObj.weldlog_expertise_id.expertise_id ? val : '' : '') : ''
        // console.log(this.modelObj.weldlog_expertise_id, this.welders_id)
        // const editWelder = this.modelObj.id ? this.welders_id.find(val => val.welder_id === this.modelObj.weldlog_expertise_id ? val.expertise_id : '') : ''
        // if (welder) {
        //   newobj.weldlog_expertise_id = welder ? welder.expertise_id : this.modelObj.weldlog_expertise_id ? this.modelObj.weldlog_expertise_id : ''
        //   newobj.welding_id = welder ? welder.welder_id : this.modelObj.weldlog_expertise_id
        // } else {
        //   newobj.weldlog_expertise_id = editWelder ? editWelder.expertise_id : this.modelObj.weldlog_expertise_id ? this.modelObj.weldlog_expertise_id : ''
        //   newobj.welding_id = editWelder ? editWelder.welder_id : this.modelObj.weldlog_expertise_id
        // }
        // this.modelObj.weldlog_expertise_id = this.modelObj.weldlog_expertise_id_tmp
        // newobj.welding_id = this.modelObj.id ? this.modelObj.weldlog_expertise_id : this.modelObj.weldlog_expertise_id.welder_id ? this.modelObj.weldlog_expertise_id.welder_id : this.modelObj.weldlog_expertise_id
        newobj.product_id = []
        newobj.batch_no = []
        this.warehouseProductBatches.forEach((productBatchObj) => {
          newobj.product_id.push(productBatchObj.product_id || 0)
          newobj.batch_no.push(productBatchObj.batch_no || '')
        })
        newobj.product_id = newobj.product_id.join('$$$')
        newobj.batch_no = newobj.batch_no.join('$$$')
        if (newobj.drawing_nr) {
          var as = newobj.drawing_nr.split('(')
          newobj.drawing_nr = as[0].trim()
        }
        setTimeout(() => {
          for (const key in newobj) {
            if (key !== 'files') formData.append(key, key === 'is_completed' ? newobj[key] : newobj[key] || '')
          }
          const header = {
            'Content-Type': 'multipart/form-data'
          }
          let url = this.getHostRefApi
          // const method = model.id ? 'put' : 'post'
          url += model.id ? `project_weldlog/${model.id}/update` : 'project_weldlog'
          const text = model.id ? 'message.common.updatedSuccess' : 'message.common.addedSucess'
          /* on update check if there is documents in array to delete */
          const ids = [...this.deleteDocumentIds]
          if (model.id && ids.length > 0) formData.append('document_delete_ids', ids)
          hostAppApi.post(url, formData, { headers: header }) // `${url}?locale=${this.$i18n.locale}`
            .then((response) => {
              this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
              this.$router.push(this.backUrl)
            })
            // .catch(error => {
            //   if (error.response) {
            //     let exception = error.response
            //     if (error.response.data) exception = `Status Code : ${error.response.status}, Message : ${error.response.data}`
            //     else exception = `Status Code : ${error.response.status}, Message : ${error.response.statusText}`
            //     this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(exception), isException: true })
            //     if (error.response.status === 401) {
            //       this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.authFailed' })
            //       this.$store.dispatch('logout')
            //       this.$router.push('/login').catch(() => {})
            //     }
            //   } else {
            //     this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(error.message), isException: true })
            //   }
            // })
            .finally(() => {
              this.loading = false
            })
        }, 50)
      } else {
        const errorsOfNDT = [
          this.$_percentageValidation[0](this.modelObj.vt_scope),
          this.$_percentageValidation[0](this.modelObj.mt_scope),
          this.$_percentageValidation[0](this.modelObj.rt_scope),
          this.$_percentageValidation[0](this.modelObj.ut_scope)
        ]
        const errorsOflength = [
          this.$_lengthValidation[0](this.modelObj.welding_id),
          this.$_lengthValidation[0](this.modelObj.designation_1),
          this.$_lengthValidation[0](this.modelObj.thickness_1_1),
          this.$_lengthValidation[0](this.modelObj.thickness_1),
          this.$_lengthValidation[0](this.modelObj.designation_2),
          this.$_lengthValidation[0](this.modelObj.thickness_2_1),
          this.$_lengthValidation[0](this.modelObj.thickness_2)
        ]
        const text = errorsOfNDT.find(x => x !== true) || errorsOflength.find(x => x !== true)
        if (text) {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text })
        } else {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
        }
      }
      this.valueDrawing = 1
    },
    setProjectname (project) {
      let projectName = ''
      projectName += project.number ? `${project.number} - ` : ''
      projectName += project.po_name ? `${project.po_name} - ` : ''
      projectName += project.name ? project.name : ''
      return projectName
    },
    getSingleRecord (id) {
      this.fieldsLoading = true
      hostAppApi.get(`${this.getHostRefApi}project_weldlog/${id}/edit?user_id=${this.hostUserId}&project_id=${this.projectId}`)
        .then((response) => {
          if (response.data) {
            Number(response.data.project.project_config.advance_weldlog) ? this.disabledField = true : this.disabledField = false
            // it has more object get weldlog object from them
            /* eslint-disable camelcase */
            let { weldlog, selectedwps, weldlog_documents, weldlog_products, drawings, projectwps, currentusersign, project } = this.$formatter.cloneVariable(response.data)
            var drawingList = []
            if (Object.keys(drawings).length) {
              Object.keys(drawings).forEach(key => {
                drawingList.push(drawings[key])
              })
            }
            const transformData = { date: { list: ['created_at', 'weld_end'], from: 'YYYY-MM-DD HH:mm:ss', to: 'DD.MM.YYYY' } }
            const model = this.$formatter.formatModel(weldlog, transformData)
            this.modelObj = model
            this.modelObj.project_wps_nr = selectedwps
            this.modelObj.project_id = this.projectId
            this.listOfDocuments = this.$formatter.removeInvalidFromArrayObject(weldlog_documents)
            this.modelObj.po_ranked = this.projectObj.number
            this.modelObj.ao_ranked = this.projectObj.name
            this.modelObj.vt_fault_types = response.data.vt_selected_fault_types
            this.modelObj.ut_fault_types = response.data.ut_selected_fault_types
            this.modelObj.rt_fault_types = response.data.rt_selected_fault_types
            this.modelObj.mt_fault_types = response.data.mt_selected_fault_types
            this.weldingLength = weldlog.is_diameter
            this.modelObj.weld_diameter = weldlog.weld_diameter ? weldlog.weld_diameter : ''
            this.modelObj.weld_length = weldlog.weld_length ? weldlog.weld_length : ''
            this.modelObj.weldlog_compound_id = weldlog.weldlog_compound_id ? weldlog.weldlog_compound_id : ''
            this.modelObj.weldlog_expertise_id_tmp = weldlog.weldlog_expertise_id ? weldlog.weldlog_expertise_id : ''
            drawingList.forEach((val, index) => {
              var value = val ? val.split('(') : ''
              if (value && value[0].trim() === weldlog.drawing_nr) {
                this.modelObj.drawing_nr = drawingList[index]
              }
            })
            if (Number(response.data.project.project_config.advance_weldlog)) {
              const selected_welding_ids = this.modelObj.welding_id ? this.modelObj.welding_id.split(', ') : []
              const selected_welding_object = response.data.welders.filter(val => selected_welding_ids.includes(val.welder_id))
              const filter_selected = []
              selected_welding_object.forEach(val => {
                filter_selected.push(val.welder_id)
              })
              const filteredArray = selected_welding_ids.filter(value => !filter_selected.includes(value))
              this.modelObj.welding_id = selected_welding_object.concat(filteredArray)
            }
            // this.modelObj.weldlog_expertise_id_tmp = this.modelObj.weldlog_expertise_id
            // const welder = this.welders_id.find(val => val.welder_id === weldlog.welding_id)
            // this.modelObj.weldlog_expertise_id = welder ? welder.welder_id : weldlog.welding_id
            // this.modelObj.welding_id = welder ? welder.welder_id : weldlog.welding_id

            if (project && project.project_config && project.project_config.warehouse) {
              const warehouseForProject = project.project_config.warehouse
              if (warehouseForProject.active) {
                /* check if has warehouse feature then do changes as #10063 */
                this.warehouseEnabled = this.features && this.features.warehouse && this.features.warehouse.enabled
                this.getMaterials()
              } else this.warehouseEnabled = false
            }
            this.currentUserSign = currentusersign || ''
            this.listOfDrawings = drawingList || []
            this.listOfprojectwps = projectwps || []
            this.weldCompound = response.data.weldlog_compounds || []
            this.welders_id = response.data.welders || []
            if (!weldlog_products.length) this.addNewProductBatchObj()
            /* set weldlog Products */
            weldlog_products = weldlog_products || []
            weldlog_products.forEach(product => {
              const obj = { product_id: product.product_id, batch_no: product.batch_no, listOfBatches: product.batches, batchLoading: false }
              this.warehouseProductBatches.push(obj)
            })
          }
        })
        .finally(() => {
          this.fieldsLoading = false
        })
    },
    setSignDate (prop) {
      this.$set(this.modelObj, prop, this.currentUserSign)
    },
    setWeldEnd (prop) {
      const weld_end = this.modelObj.weld_end ? this.modelObj.weld_end : moment().format('DD.MM.YYYY')
      this.modelObj.weld_end = this.modelObj.is_completed ? weld_end : ''
      // this.modelObj.weld_end = currentDate
      // this.$set(this.modelObj, prop, currentDate)
    },
    updateWPSnr () {
      // Get all wps_nr not in projectwps and add the project_wps_nr numbers then do join them
      // Step 1 - Get all wps_nr not in projectwps
      let oldWpsNr = this.$formatter.cloneVariable(this.modelObj.wps_nr)
      oldWpsNr = oldWpsNr ? oldWpsNr.split(',') : []
      oldWpsNr = oldWpsNr.filter(num => !this.listOfprojectwps.includes(num))
      // Step 2 add the project_wps_nr numbers
      const newWpsNrs = [...oldWpsNr, ...this.modelObj.project_wps_nr]
      // Step 3 do join them
      this.$set(this.modelObj, 'wps_nr', newWpsNrs.join())
    },
    getMaterials () {
      hostAppApi.get(`${this.getHostRefApi}get_weldlog_materials?user_id=${this.userId}&project_id=${this.modelObj.project_id}`)
        .then((response) => {
          if (response.data) {
            this.listOfProducts = response.data || []
            if (!this.$route.params.id && this.warehouseProductBatches && this.warehouseProductBatches.length < 1) this.addNewProductBatchObj()
          }
        })
    },
    getBatchByMaterials (index, productId) {
      this.warehouseProductBatches[index].batchLoading = true
      hostAppApi.get(`${this.getHostRefApi}get_batch_by_material/${productId}?user_id=${this.hostUserId}&project_id=${this.modelObj.project_id}`)
        .then((response) => {
          if (response.data) {
            const sameProductsExisting = this.warehouseProductBatches.filter(x => x.product_id === productId)
            const batchNos = sameProductsExisting.map(x => x.batch_no)
            this.warehouseProductBatches[index].listOfBatches = response.data.filter(x => !batchNos.includes(x))
          }
        })
        .finally(() => {
          this.warehouseProductBatches[index].batchLoading = false
        })
    },
    addNewProductBatchObj () {
      this.warehouseProductBatches.push({ product_id: 0, batch_no: '', listOfBatches: [], batchLoading: false })
    },
    changeLength () {
      this.weldingLength = this.modelObj.is_diameter
    },
    setWelders (val) {
      // if (this.modelObj.weldlog_expertise_id) {
      //   hostAppApi.post(`${this.getHostRefApi}expertise/getwelders?name=${this.modelObj.weldlog_expertise_id.expertise_id}`)
      //     .then(response => {})
      // }
    },
    changeWeldCompound () {
      if (this.modelObj.weldlog_compound_id) {
        hostAppApi.post(`${this.getHostRefApi}fault_type/getWeldlogFaults?weldlog_compound_id=${this.modelObj.weldlog_compound_id}`)
          .then(response => {
            this.weldlog_fault_types = response.data
          })
      }
    },
    scanQrCode () {
      this.scanQRDialog = true
    },
    async getRespectiveProductOrder () {
      this.selectedLocation = ''
      if (this.scanCode) {
        this.scanCode = this.scanCode.trim()
        this.productLoading = true
        const list = []
        if (list) {
          // to clear values
          this.selectBatch = ''
          this.pickQty = ''
          this.moreLocationsFound = false
          this.batchLocations = []
          let getProduct = list.find(x => x.barcode === this.scanCode || x.product_number === this.scanCode || (x.batches && Array.isArray(x.batches) && x.batches.find(y => y.value.toString() === this.scanCode)) || (x.qrcodes && Array.isArray(x.qrcodes) && x.qrcodes.find(z => z.qr_code.toString() === this.scanCode)))
          this.unPlannedProduct = false
          if (!getProduct) {
            const response = await this.getProductByNumber()
            if (response && response.data) {
              getProduct = response.data
              getProduct.orderid = this.orderModel.id
              this.unPlannedProduct = true
            }
          }
          if (getProduct) {
            const filteredQrCodeBatch = getProduct.qr_code_batch.filter(x => x !== '')
            if (filteredQrCodeBatch.length !== 0) {
              this.selectBatch = filteredQrCodeBatch[0]
            }
            this.productInfo = getProduct
            this.productFound = true
            this.listOfLocations = getProduct.locations
            const getStockLocations = getProduct.locations.filter(x => x.availableqty > 0)
            this.getBatch()
            var setBatchValue = getProduct.batches.find(y => y.value.toString() === this.scanCode)
            if (setBatchValue && setBatchValue.value) {
              this.selectBatch = setBatchValue.value
              this.checkTheBatchnumber()
            }
            var setBatchValueInQRCode = getProduct.qrcodes.find(x => x.qr_code === this.scanCode)
            if (setBatchValueInQRCode && setBatchValueInQRCode.batch_number && !getProduct.qr_code_batch.length) {
              this.selectBatch = setBatchValueInQRCode.batch_number
              this.checkTheBatchnumber()
            }
            if (this.productInfo.producttype === 'work') {
              setTimeout(() => {
                this.focusField('qtyRef')
              }, 50)
            }
            if (getProduct.isbatch) {
              setTimeout(() => {
                this.focusField('batchRef')
              }, 50)
            } else {
              setTimeout(() => {
                this.focusField('moreLocation')
              }, 50)
            }
            if (getStockLocations.length === 1) {
              setTimeout(() => {
                this.selectedLocation = getStockLocations[0].locationname
              }, 50)
            } else if (getStockLocations.length > 1) {
              setTimeout(() => {
                this.focusField('moreLocation')
              }, 100)
            }
            if (this.selectBatch !== '') {
              this.checkTheBatchnumber()
            }
          } else {
            this.productInfo = {}
            this.productFound = false
            this.listOfLocations = []
            this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'message.product.noProductFound' })
            this.focusField('scanFocus')
            this.scanCode = ''
          }
        }
        this.productLoading = false
      }
    },
    getProductByNumber () {
      return hostAppApi.get(`${this.getHostRefApi}get_product_info?search_term=${this.scanCode}&user_id=${this.userId}`)
    },
    onResizeHandler () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight - 40 }
    },
    decreaseQty () {
      if (this.pickQty > 0) this.pickQty--
    },
    increaseQty () {
      if (this.pickQty < 0) this.pickQty = ''
      this.pickQty++
    },
    checkTheBatchnumber () {
      this.pickQty = ''
      this.selectedLocation = ''
      this.moreLocationsFound = false
      this.batchLocations = []
      const products = this.$formatter.cloneVariable(this.productInfo)
      let locations = []
      if (products && products.batches) {
        this.loadingBatch = true
        const getStock = products.batches
        // for (let i = 0; i < getStock.length; i++) {
        //   const getBatches = getStock[i].batches
        //   if (getBatches) {
        //     const getBatchWithStock = getBatches.find(x => x.batchnumberpwa === this.selectBatch)
        //     if (getBatchWithStock) {
        //       getBatchWithStock.locationid = getStock[i].locationid
        //       getBatchWithStock.locationname = getStock[i].locationname
        //       batches.push(getBatchWithStock)
        //     }
        //   }
        // }
        let getOnStock = 0
        if (getStock) {
          const getBatchWithStock = getStock.find(x => x.batchnumberpwa === this.selectBatch)
          locations = getBatchWithStock ? getBatchWithStock.locations : []
          this.listOfLocations = locations
          getOnStock = getBatchWithStock ? getBatchWithStock.onstock : ''
        }
        if (locations && locations.length <= 0 && !this.selectBatch) {
          this.selectBatch = ''
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.order.notValidBatch') })
          setTimeout(() => {
            this.focusField('batchRef')
          })
        }
        if (locations && locations.length > 1) {
          this.batchLocations = locations
          this.moreLocationsFound = true
          this.focusField('moreLocation')
        } else {
          this.batchLocations = locations
          this.moreLocationsFound = true
          if (locations) {
            if (getOnStock > this.productInfo.orderedqty) this.pickQty = this.productInfo.orderedqty
            else this.pickQty = getOnStock
            this.selectedLocation = locations[0] ? locations[0].locationname : ''
            this.focusField('qtyRef')
          }
        }
        this.loadingBatch = false
      }
    },
    getBatch () {
      this.getbatch = []
      for (let i = 0; i < this.listOfLocations.length; i++) {
        if (Array.isArray(this.listOfLocations[i].batches)) {
          for (let j = 0; j < this.listOfLocations[i].batches.length; j++) {
            this.getbatch.push(this.listOfLocations[i].batches[j])
          }
        }
      }
    },
    getItemText (item) {
      const qty = item.qty.split('.')[0]
      return `${item.locationname} (${qty})`
    },
    pickQtyOnOrder () {
      const pickedQty = +this.$formatter.replaceCommaWithDot(this.pickQty || 0)
      if (this.$refs.pickForm.validate() && pickedQty > 0) {
        this.saveLoading = true
        const locations = this.listOfLocations
        const getOnStock = locations.find(x => x.locationname === this.selectedLocation)
        let warehouseid = 0
        let locationid = 0
        let locationname = ''
        if (getOnStock) {
          locationid = getOnStock.locationid
          warehouseid = getOnStock.warehouseid
          locationname = getOnStock.locationname
        }
        const productObj = this.$formatter.cloneVariable(this.productInfo)
        productObj.qty = this.$formatter.replaceCommaWithDot(this.productInfo.orderedqty)
        productObj.pickedqty = pickedQty
        productObj.invoiceqty = pickedQty
        productObj.batchnumberpwa = this.productInfo && this.productInfo.isbatch ? this.selectBatch : null
        productObj.locationid = locationid
        productObj.warehouseid = warehouseid
        if (this.unPlannedProduct) { // only for unplanned product
          productObj.id = 0
          productObj.locationname = locationname
          const obj = this.listOfWarehouses.find(x => x.id === warehouseid)
          if (obj) productObj.warehousename = obj.shortname
          productObj.order_quantity = productObj.orderedqty
        }
        productObj.weldlog_id = this.$route.params.id ? parseInt(this.$route.params.id) : 0
        productObj.is_weldlog_order = 1
        productObj.dg = this.dgCalculationFormula(productObj.costprice, productObj.price, productObj.discount)
        const model = [productObj]
        hostAppApi.post(`${this.getHostRefApi}products/update_materials/${this.projectId}?fromPwa=true&user_id=${this.userId}&is_timer_project=1`, model)
          .then((response) => {
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'message.common.updatedSuccess' })
            // this.getMaterials()
            const batches = []
            if (response.data.batch_number) {
              if (this.productInfo.batches) {
                this.productInfo.batches.forEach((productBatchObj) => {
                  batches.push(productBatchObj.batchnumberpwa)
                })
              }
              const obj = { product_id: response.data.product_number, batch_no: response.data.batch_number, batches: batches, listOfBatches: batches, batchLoading: false }
              this.warehouseProductBatches.push(obj)
              const sameProductsExisting = this.warehouseProductBatches.filter(x => x.product_id === response.data.product_number)
              const batchNos = sameProductsExisting.filter(x => x.batch_no === response.data.batch_number)
              if (batchNos.length > 1) {
                this.warehouseProductBatches.pop()
              }
            }
          })
          .finally(() => {
            this.saveLoading = false
            this.scanQRDialog = false
            this.scanCode = ''
            this.productFound = false
          })
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.common.validationIssue') })
      }
    },
    dgCalculationFormula (price, salesprice, discount) {
      // FORMULA
      // (price - product costprice) / price * 100 => without discount
      // If Discount
      // price * (100 - discount) / 100
      // (result - costprice) / result * 100
      let getDGValue = 0
      getDGValue = price > 0 ? ((salesprice - price) / salesprice) * 100 : 0
      if (discount && discount > 0) {
        const getResult = +salesprice * (100 - discount) / 100
        const dgValue = ((getResult - price) / getResult) * 100
        getDGValue = dgValue
      }
      return (isNaN(getDGValue) ? 0 : (getDGValue || 0))
    },
    checkIfLocationValid () {
      this.loadingLocation = true
      this.pickQty = ''
      const locations = this.listOfLocations
      const getOnStockValue = locations.find(x => x.locationname === this.selectedLocation)
      if (!getOnStockValue) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.product.noLocFound' })
        setTimeout(() => {
          this.pickQty = ''
          this.selectedLocation = ''
          this.focusField('moreLocation')
        })
      } else {
        if (this.productInfo.isbatch) {
          const getBatch = getOnStockValue.batches.find(x => x.batchnumberpwa === this.selectBatch)
          if (getBatch) {
            if (getBatch.onstock > this.productInfo.orderedqty) this.pickQty = this.productInfo.orderedqty
            else this.pickQty = getBatch.onstock
          }
        } else {
          if (getOnStockValue.availableqty > this.productInfo.orderedqty) this.pickQty = this.productInfo.orderedqty
          else this.pickQty = getOnStockValue.availableqty
        }
        this.focusField('qtyRef')
      }
      this.loadingLocation = false
    },
    focusField (value) {
      requestAnimationFrame(() => {
        if (this.$refs[value]) {
          setTimeout(() => {
            this.$refs[value].focus()
          })
        }
      })
    },
    cancelScanQRDialog () {
      this.saveLoading = false
      this.scanQRDialog = false
      this.scanCode = ''
      this.productFound = false
    },
    removeBatchRow (index) {
      this.warehouseProductBatches.splice(index, 1)
    },
    changeDrawingNumber () {
      if (this.modelObj && this.modelObj.drawing_nr && this.modelObj.is_diameter && this.valueDrawing !== 1) {
        var drawing_nr = this.modelObj && this.modelObj.drawing_nr ? this.modelObj.drawing_nr.split('(')[0] : ''
        hostAppApi.get(`${this.getHostRefApi}get_drawing/${this.$route.params.project_id}?drawing_id=${drawing_nr}`)
          .then(response => {
            this.modelObj.pipe_description = response.data.drawing.description
          })
          .catch(() => {
            this.modelObj.pipe_description = ''
          })
      }
      this.valueDrawing++
    }
  },
  watch: {
    isShowDocumentErrors (val) {
      this.hideDetails = !val
    },
    'modelObj.weldlog_compound_id' (val, oldVal) {
      if (val !== oldVal) this.changeWeldCompound()
      if (!this.modelObj.Welding_compound) this.weldlog_fault_types = []
    },
    'modelObj.welding_id' (val) {
      const expertise_ids = []
      const welder_ids = []
      if (val !== null && typeof val === 'object') {
        val.forEach((v, i) => {
          if (v !== null && typeof v === 'object') {
            if (v.expertise_id) {
              expertise_ids.push(v.expertise_id)
            }
            if (v.welder_id) {
              welder_ids.push(v.welder_id)
            }
          } else {
            welder_ids.push(v)
          }
        })
        this.modelObj.weldlog_expertise_id = expertise_ids.join(', ')
        this.modelObj.welding_id_tmp = welder_ids.join(', ')
        this.modelObj.weldlog_expertise_id_tmp = expertise_ids.join(', ')
      }
      if (this.welders_id.includes(val)) {
        this.fileDisabled = true
        // this.setWelders(val)
      } else this.fileDisabled = false
    },
    pickQty (val) {
      const pickedQty = +this.$formatter.replaceCommaWithDot(this.pickQty || 0)
      const locations = this.listOfLocations
      if (this.selectedLocation) {
        const getOnStockValue = locations.find(x => x.locationname === this.selectedLocation)
        if (getOnStockValue) {
          if (this.productInfo.isbatch && getOnStockValue.batches) {
            const getBatchQty = getOnStockValue.batches
            const getObj = getBatchQty.find(x => x.batchnumberpwa === this.selectBatch)
            if (getObj) {
              if (pickedQty > getObj.onstock) {
                this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.order.qtyExceeds' })
                setTimeout(() => {
                  this.pickQty = getObj.onstock
                })
              }
            } else {
              const onstock = getOnStockValue.availableqty
              if (pickedQty > onstock) {
                this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.order.qtyExceeds' })
                setTimeout(() => {
                  this.pickQty = onstock
                })
              }
            }
          } else {
            const onstock = getOnStockValue.availableqty
            if (pickedQty > onstock) {
              this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.order.qtyExceeds' })
              setTimeout(() => {
                this.pickQty = onstock
              })
            }
          }
        } else {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.product.noLocFound' })
          setTimeout(() => {
            this.pickQty = ''
            this.selectedLocation = ''
            this.focusField('moreLocation')
          })
        }
      }
      if (this.unPlannedProduct) this.productInfo.orderedqty = pickedQty
      const orderedQty = this.productInfo.orderedqty
      if (pickedQty > orderedQty) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.order.qtyExceeds' })
        setTimeout(() => {
          this.pickQty = orderedQty
        })
      }
    }
  }
}
</script>

<style>
.custom_checkbox_margin > .v-input--selection-controls.v-input {
  margin: 0px !important;
}
.custom_border {
  border: 1px solid #ada9a9;
  padding: 1px;
}
</style>
